import React, { useState } from 'react'
import { fallDown as Burger } from "burger-menu";
import 'burger-menu/lib/index.css';
import { HiOutlineMenuAlt3 } from 'react-icons/hi';
import './header.css'
import { Link } from 'react-scroll';
import { menuLinks } from '../../pages';

// const mobileMenus = [
//   {
//     id: 1,
//     title: 'Home',
//     link: '/home',
//     to: 'homePage'
//   },
//   {
//     id: 2,
//     title: 'About',
//     link: '/about',
//     to: 'aboutPage'
    
//   },
//   {
//     id: 3,
//     title: 'Services',
//     link: '/services',
//     to: 'servicePage'
//   },
//   {
//     id: 4,
//     title: 'Product',
//     link: '/product',
//     to: 'productPage'
//   },
//   {
//     id: 5,
//     title: 'Careers',
//     link: '/career',
//     to: 'careerPage'
//   },
//   {
//     id: 6,
//     title: 'Contact Us',
//     link: '/contact',
//     to: 'contactPage'
//   }
// ]

const MobileHeader = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div role='presentation' onClick={() => setIsOpen(!isOpen)}><HiOutlineMenuAlt3 className='hm-menu' /></div>
      <Burger htmlClassName='mob-menu' width="100%" className="burger-menu" isOpen={isOpen} selectedkey={'entry'} onClose={() => setIsOpen(false)}>
        {menuLinks.map(({ id, title, to }) => (
          <Link
            key={id}
            to={to}
            spy
            offset={-80}
            smooth
            duration={500}
            containerId="scrollElement"
            href="#"
          >
            <div role="presentation" onClick={() => setIsOpen(false)}>{title}</div>
          </Link>
        ))}
        
      </Burger>
    </>
  )
}

export default MobileHeader
