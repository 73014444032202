import React from "react";
import "./banner.css";
import { Button, Col, Row } from "react-bootstrap";
import { Link } from "react-scroll";

const Banner = () => {
  return (
    <Row className="g-0 ">
      <Col xs={12} md={6}>
        <div className="p-4 p-sm-5 d-flex h-100 justify-content-center align-items-center bg-green ">
          <div>
            <h1>
              Effortless all <br />
              Battery Management <br />
              Starts Here!
            </h1>
            <p>
              We&apos;re dedicated to delivering cutting-edge
              Battery Management System solutions that optimize the
              performance and lifespan of batteries in various
              applications.
            </p>
            <Button
              to="servicePage"
              spy
              offset={-95}
              smooth
              duration={500}
              containerId="scrollElement"
              href="#"
              variant="outline-success"
              className="btn-success"
              as={Link}
            >
              Explore Our Services
            </Button>
          </div>
        </div>
      </Col>
      <Col xs={12} md={6}>
        <figure className="hero-slide">
          <img src="banner1.jpg" alt="aktivolt.com" />
        </figure>
      </Col>
    </Row>
  );
};

export default Banner;
