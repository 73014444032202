import React from "react";
import { Button, Col, Container, Row, Modal } from "react-bootstrap";
import "./teams.css";
import { useState } from "react";

import ContactForm from "./contactForm";

 
const Teams = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <div className="section-teams">
      <Container>
        <Row>
          <Col xs={12}>
            <h4>Powering Innovation Together!</h4>
            <p className="mt-3">
              We are a community of passionate individuals dedicated to pushing
              the boundaries of Battery Management System (BMS) technology. If
              you are driven by innovation, excellence, and a desire to make a
              meaningful impact in the world of energy storage.
              {/* we invite you to join our dynamic team. */}
            </p>

            <Button
              variant="outline-success"
              className="mt-3 btn-success"
              onClick={handleShow}
            >
              Get in touch
            </Button>
          </Col>
        </Row>
      </Container>

      <Modal size="lg" show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Get in touch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <ContactForm /> 
          </Container>
        </Modal.Body> 
      </Modal>
    </div>
  );
};

export default Teams;
