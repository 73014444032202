import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Helmet } from "react-helmet";

function ApplicationHelmet({ children }) {
  const data = useStaticQuery(graphql`
  query SiteInfo {
    site{
    siteMetadata {
    title
      description
      keywords
      author
      robots
      canonical
      siteUrl
    }
    }
  }
  `)
  const {title, description, keywords, author, robots, canonical} = data.site.siteMetadata;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
        <meta name="author" content={author} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <meta name="robots" content={robots} />
        <link rel="canonical" href={canonical} />
        <meta property="og:title" content={author} />
        <meta property="og:description" content={description} />
        <meta property="og:image" content="https://aktivolt.com/aktivolt.png" />
        <meta property="og:type" content="BMS" />
        <meta property="og:url" content={canonical} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <link href="https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&family=Poppins:wght@400;500;800;900&display=swap" rel="stylesheet" />
        <meta name="msapplication-TileColor" content="#245b27" />
        <meta name="theme-color" content="#245b27" />   
        <meta name="robots" content="index, follow"></meta>
      </Helmet>
      {children}
    </>
  );
};

export default ApplicationHelmet;