import React, { useEffect, useRef, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";

import MobileHeader from "../components/Header";
import Banner from "../components/Banner";
import About from "../components/About";
import ApplicationHelmet from "../components/Helmet";

import "../commons.css";

import { Col, Container, Nav, Navbar, Row } from "react-bootstrap";
import * as Scroll from "react-scroll";
import Services from "../components/Services";
// import PowerBMNeeds from "../components/PowerBMNeeds";
import Teams from "../components/Teams";
import Footer from "../components/Footer";
import Features from "../components/Features";

const { Element, Events, scrollSpy, Link } = Scroll;

export const menuLinks = [
  {
    id: 1,
    title: "Home",
    link: "/home",
    to: "homePage",
  },
  {
    id: 2,
    title: "About Us",
    link: "/about",
    to: "aboutPage",
  },
  {
    id: 3,
    title: "Our Services",
    link: "/services",
    to: "servicePage",
  },
  // {
  //   id: 4,
  //   title: "Products",
  //   link: "/products",
  //   to: "productPage",
  // },
  // {
  //   id: 5,
  //   title: "Careers",
  //   link: "/careers",
  //   to: "careersPage",
  // },
  {
    id: 6,
    title: "Contact Us",
    link: "/contact",
    to: "contactPage",
  },
];

const App = () => {
  const containerRef = useRef(null);
  const [navBackground, setNavBackground] = useState("appBarTransparent");
  const [headerSticky, setHeaderSticky] = useState(false);

  const handleScroll = (e) => {
    const show = containerRef.current.childBindings.domNode.scrollTop > 90;
    if (show) {
      // setNavBackground("appBarSolid");
      setHeaderSticky(true);
    } else {
      setNavBackground("appBarTransparent");
      setHeaderSticky(false);
    }
  };

  useEffect(() => {
    Events.scrollEvent.register("begin", () => { });
    Events.scrollEvent.register("end", () => { });
    scrollSpy.update();

    return () => {
      Events.scrollEvent.register("begin", () => { });
      Events.scrollEvent.register("end", () => { });
      scrollSpy.update();
      window.removeEventListener("load", function () {
        console.log("2");
      });
    };
  }, []);

  return (
    <>
      <ApplicationHelmet>
        <Element
          style={{
            overflowY: "auto",
            maxHeight: "101vh",
          }}
          id="scrollElement"
          ref={containerRef}
          onScroll={handleScroll}
          name="homePage"
        >
          <div className="header-static">
            <Navbar
              fixed={headerSticky ? "top" : ""}
              className={`${navBackground} block-header`}
              bg="transparent"
            >
              <Container>
                <Row className="align-items-center">
                  <Col md={2} xs={10} lg={2}>
                    <Navbar.Brand>
                      <Link
                        key={1}
                        to='homePage'
                        spy
                        offset={-15}
                        smooth
                        duration={500}
                        containerId="scrollElement"
                        href="#"
                      >
                        <img src="logo.svg" alt="Aktivolt" title="Aktivolt" />
                      </Link>
                    </Navbar.Brand>
                  </Col>
                  <Col xs={10} className="d-none d-lg-block">
                    <Nav className="nav-center justify-content-end">
                      {menuLinks.map(({ id, title, to }) => (
                        <Link
                          key={id}
                          to={to}
                          spy
                          smooth
                          offset={-90}
                          duration={500}
                          containerId="scrollElement"
                          href="#"
                        >
                          {title}
                        </Link>
                      ))}
                    </Nav>
                  </Col>
                  <Col xs={2} md={10} lg={2}>
                    <div className="d-block d-md-block d-lg-none text-end">
                      <MobileHeader />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Navbar>
          </div>
          <Element name="homePage">
            <Banner />
          </Element>
          <Element name="aboutPage">
            <About />
          </Element>
          <Features />
          <Element name="servicePage">
            <Services />
          </Element>
          {/* <Element name="productPage">
          <PowerBMNeeds />
        </Element> */}
          <Element name="contactPage">
            <Teams />
          </Element>
          <Footer />
        </Element>
      </ApplicationHelmet>
    </>
  );
};

export default App;
export const Head = () => <title>Home Page</title>;
