import React from 'react'
import './footer.css'

const Footer = () => {
    const currentYear = new Date().getFullYear();
    return (
        <div className='footer'>
            <div class="container justify-content-center">
                <div class="row mb-5">
                    <div class="col-xs-12 col-md-3">
                        <div class="footer-logo mb-3">
                            <img src="logo.svg" alt="aktivolt-logo" />
                        </div>
                    </div>
                    <div class="col-xs-12 col-md-9">
                        <div class="row mt-4">
                            {/* <h4 class="contact">Contact Us</h4> */}
                            <div class="col-xs-12 col-md-3 mb-3">
                                <div class="d-flex icon-1">
                                    <img src="footer-email.svg" alt="mailaddress" />
                                    <a href="mailto:support@aktivolt.com" class="content">
                                        <span>Email us for query</span> <br />
                                        support@aktivolt.com
                                    </a>
                                </div>
                            </div>
                            <div class="col-xs-12 col-md-5 mb-3">
                                <address class="d-flex icon-2">
                                    <img src="footer-address.svg" alt="aktivolt address" />
                                    <a href="https://maps.app.goo.gl/kNp1nWJC1UF9sqKG7" target='_blank' class="content"> <span>Address</span><br />
                                        Mfar Silverline Teck Park, Whitefield,<br /> EPIP 2nd phase Bengaluru. </a>
                                </address>
                            </div>
                            <div class="col-xs-12 col-md-4">
                                <div class="d-flex icon-3">
                                    <img src="footer-address.svg" alt="aktivolt contact" />
                                    <a href="tel:8838744678" class="content">
                                        <span>Call us for support</span> <br />
                                        +91 8838744678</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="footer-bottom text-center">
                <span>&copy;</span> {currentYear} All Rights Reserved <a href="/">aktivolt.com</a>
            </div>

        </div>
    )
}

export default Footer