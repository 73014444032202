import { Formik } from "formik";

import React, { useState } from "react";

import { Form, Button, Alert, Col, Row } from "react-bootstrap";

import * as Yup from "yup";

// const contactUri =
//   process.env.GATSBY_ENV === "development"
//     ? "http://localhost:8002/contact"
//     : "https://aktivolt.in/contact";

const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        email: "",
        mobileNumber: "",
        fullAddress: "",
        message: "",
      }}
      validationSchema={Yup.object().shape({
        name: Yup.string()
          .required("Name is required"),
        email: Yup.string()
          .email("Must be a valid email")
          .max(255)
          .required("Email is required"),
        mobileNumber: Yup.string()
          .required("Mobile number is required"),
        fullAddress: Yup.string()
          .required("Address is required"),
        message: Yup.string()
          .min(10)
          .required("Message is required"),
      })}
      onSubmit={async (
        values,
        { setErrors, setStatus, setSubmitting, resetForm }
      ) => {
        setIsLoading(true);

        try {
          await fetch(`https://formspree.io/f/mknlevyp`, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(values),
          }).then((res) => {
            if (res.status === 200) {
              resetForm();
              setStatus({ success: true });
              setSubmitting(false);
              setIsSuccess(true);
              setIsError(false);
              setIsLoading(false);

              setTimeout(() => {
                setIsSuccess(false);
              }, 6000);
            } else {
              resetForm();
              setStatus({ success: false });
              setSubmitting(false);
              setIsSuccess(false);
              setIsError(true);
              setIsLoading(true);

              setTimeout(() => {
                setIsSuccess(true);
              }, 6000);
            }
          });
        } catch (err) {
          setStatus({ success: false });
          setErrors({ submit: err });
          setSubmitting(false);
          setIsSuccess(false);
          setIsError(true);
          setIsLoading(false);

          setTimeout(() => {
            setIsError(true);
          }, 6000);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <Form onSubmit={handleSubmit}>
          {isSuccess && (
            <Alert variant="success">
              <strong>Thank you for getting in touch!</strong>
              We appreciate you contacting us Aktivolt. One of our colleagues will
              get back in touch with you soon! Have a great day!
            </Alert>
          )}

          {isError && (
            <Alert variant="danger">
              There was an error trying to send your message. Please try again
              later.
            </Alert>
          )}

          {isLoading && (
            <div className="overlay-form">
              <div class="loader"></div>
            </div>
          )}
          <Row>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-4">
                <Form.Control
                  isInvalid={Boolean(touched.name && errors.name)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="name"
                  value={values.name}
                  type="text"
                  placeholder="Name"
                />

                {errors.name && <p className="error-msg">{errors.name}</p>}
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-4">
                <Form.Control
                  isInvalid={Boolean(touched.email && errors.email)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="email"
                  value={values.email}
                  name="email"
                  placeholder="Email"
                />

                {errors.email && <p className="error-msg">{errors.email}</p>}
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-4">
                <Form.Control
                  isInvalid={Boolean(touched.mobileNumber && errors.mobileNumber)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  type="text"
                  value={values.mobileNumber}
                  name="mobileNumber"
                  placeholder="Mobile Number"
                />

                {errors.mobileNumber && (
                  <p className="error-msg">{errors.mobileNumber}</p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12} sm={6}>
              <Form.Group className="mb-4">
                <Form.Control
                  isInvalid={Boolean(touched.fullAddress && errors.fullAddress)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="fullAddress"
                  value={values.fullAddress}
                  type="text"
                  placeholder="Full Address"
                />
                {errors.fullAddress && (
                  <p className="error-msg">{errors.fullAddress}</p>
                )}
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-0">
                <Form.Control
                  isInvalid={Boolean(touched.message && errors.message)}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  name="message"
                  value={values.message}
                  as="textarea"
                  rows={5}
                  placeholder="Your Message"
                />

                {errors.message && <p className="error-msg">{errors.message}</p>}
              </Form.Group>
            </Col>
            <div className="text-right">
              <Button
                variant="success"
                type="submit"
                className="btn-inquiry"
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default ContactForm;
