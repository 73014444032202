import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./about.css";

const About = () => {
  return (
    <div className="section-about">
      <Container>
        <Row >
          <Col xs={12} md={12} lg={6} style={{ textAlign: 'center' }}>
            <img src="about-us.png" alt="About" title="About" className="aboutImg" />
          </Col>
          <Col xs={12} md={12} lg={6} className="about-info">
            <h3 className="section-title">About <span>us</span></h3>
            <p className="section-description mt-4">
              Battery Management System solutions that optimize the performance and lifespan of
              batteries in various applications.
            </p>
            <p className="description mt-3">
              At aktivolt, we're dedicated to delivering cutting-edge Battery
              Management System solutions that optimize the performance and
              lifespan of batteries in various applications. Whether you're in
              the automotive industry, renewable energy sector, or any other
              field reliant on battery technology, our BMS solutions are
              designed to meet your unique needs.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
