import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./features.css";
const Features = () => {
  return (
    <div className="features">
    <div
      className="section-features"
      style={{
        background: "url('aktivolt-feature1.png') top -20px left no-repeat, url('aktivolt-feature2.png') bottom -25px right no-repeat, url('aktivolt-feature3.png') center center no-repeat",
        backgroundSize: "10% auto"

      }}
    >
      <Container>
        <Row>
          <Col xs={12} md={12}>
            <h3 className="section-title text-center">
              <span>Why Choose</span> Aktivolt?
            </h3>
            <div className="feature-list">
              <ul>
                <li>
                  <div className="feature-details">
                    <h3>Proven Expertise</h3>
                    <p>
                      With years of experience in the field, we have a track
                      record of delivering reliable BMS solutions to satisfied
                      clients across various industries.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="feature-details">
                    <h3>Custom Solutions</h3>
                    <p>
                      We understand that every application is unique. Our BMS systems are designed to be customizable to fit your exact needs.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="feature-details">
                    <h3>Safety First</h3>
                    <p>
                      Battery safety is paramount. Our BMS solutions prioritize safety, preventing overcharging, over-discharging, and other potentially hazardous conditions.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="feature-details">
                    <h3>Advanced Technology</h3>
                    <p>
                      We stay at the forefront of battery technology trends, ensuring that our BMS systems incorporate the latest innovations.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="feature-details">
                    <h3>Cost-Effective</h3>
                    <p>
                      Our solutions are not only advanced but also cost-effective, helping you get the most out of your battery investments.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
    </div>
  );
};

export default Features;
