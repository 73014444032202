import React from "react";
import { Col, Container, Row } from "react-bootstrap";

import "./services.css";
const Services = () => {
  return (
    <div   className="section-service">
      <Container>
        <Row>
          <Col xs={12} md={12} lg={6}>
            <h3 className="section-title">
              <span>Our</span> Services
            </h3>
            <p className="section-description mt-4">
              Our BMS solutions provide real-time data and advanced features
              that empower you to make informed decisions and maximize your
              battery investments.
            </p>
          </Col>
          <Col xs={12} md={12} className="service-info">
            <Row className=" mt-3 mb-3 service-info-details">
              <Col xs={8} md={6} lg={3} className="service-info-cards">
                <div className="service-card"> 
                  <h4>BMS Design and Development</h4>
                  <p>
                    We specialize in designing custom BMS solutions tailored to
                    your specific application, ensuring optimal battery
                    performance and safety.
                  </p>
                </div>
              </Col>
              <Col xs={8} md={6} lg={3}  className="service-info-cards">
              <div className="service-card"> 
                <h4>BMS Integration</h4>
                <p>
                  Our team of experts can seamlessly integrate our BMS systems
                  into your existing battery systems, making the transition
                  smooth and efficient.
                </p>
              </div>
              </Col>
              <Col xs={8} md={6} lg={3}  className="service-info-cards">
              <div className="service-card"> 
                <h4>BMS Monitoring & Maintenance</h4>
                <p>
                  We offer comprehensive monitoring and maintenance services to
                  keep your battery systems operating at their best.
                </p>
              </div>
              </Col>
              <Col xs={8} md={6} lg={3}  className="service-info-cards">
              <div className="service-card"> 
                <h4>Technical Support</h4>
                <p>
                  Our dedicated technical support team is available to assist
                  you with any questions or issues you may encounter.
                </p>
              </div>
              </Col> 
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Services;
